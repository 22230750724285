import React, { useState } from "react";
import { Button, Modal, Form, Select, notification } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { api, mask } from '../../const';
import type { FormProps } from 'antd';

type FieldType = {
    serverrole?: string;
    siteroles?: any;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

interface RolesModalProps {
    data: any;
    ad: {
        steamid: string;
        nickname: string;
        roles: {
            serverrole: string;
            siteroles: string[];
        };
    };
    updateData: any;
    options: any;
}

const RolesModal: React.FC<RolesModalProps> = ({data,ad,updateData,options}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [serverrole, setServerrole] = useState(ad.roles.serverrole);
    const [siteroles, setSiteroles] = useState<string[]>(ad.roles.siteroles);
    const [api1, contextHolder] = notification.useNotification({
        stack: {threshold: 1},
      });
    

    const openNotification = () => {
        api1.success({
            message: `Данные успешно обновлены!`,
            description:
                `Администратор: ${ad.nickname}, ${ad.steamid}\n` +
                `Серверная роль: ${serverrole}, \n` +
                `Сайт-роли: ${siteroles.join(', ')}`,
            placement: 'bottom',
        });
    };

    const sendNewData = async () => {
        try {
            const newData = {
                steamid: ad.steamid,
                serverrole,
                siteroles,
            };
            console.log(newData);
            const res = await api.post('/api/admin/roles/change', newData, );
            if (res.status === 200) {
                openNotification();
                updateData();
                setModalOpen(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onFinish = (values: any) => {
        setServerrole(values.serverrole);
        setSiteroles(values.siteroles);
        sendNewData();
    };

    return (
        <>
            {contextHolder}
            <Button onClick={() => setModalOpen(true)}>Изменить <SettingOutlined /></Button>
            <Modal
                title="Изменение ролей"
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                styles={mask}
                footer={[
                    <Button style={{margin: "0 2px"}} onClick={() => setModalOpen(false)}>Закрыть</Button>
                ]}
            >
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ serverrole: ad.roles.serverrole, siteroles: ad.roles.siteroles }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Роль сервера"
                        name="serverrole"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда роль на сервере игровом!' }]}
                    >
                        <Select
                            style={{ width: 120 }}
                            onChange={(value) => setServerrole(value)}
                            value={serverrole}
                            options={options}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Роли сайта"
                        name="siteroles"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда роли для сайта!!' }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Пожалуйста, выберите хотя бы одну"
                            onChange={(value) => setSiteroles(value)}
                            value={siteroles}
                            options={options}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Подтвердить (отправить)
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default RolesModal;
