import React from "react";
import "../../css/summary.css";
import { Divider } from "antd";

export interface SummaryProps {
    osps?: any;
    os?: any;
    s?: number;
    width?: number;
    fs?: any;
}

/*
osps – own summary per day
os – full own summary in hours
s – server;
fs – full summary
*/

const SummaryHoursPerLastEnter: React.FC<SummaryProps> = ({osps, s}) => {
    const date = new Date(osps.summary * 1000);

    const d = { // Creating data of summary time from last time, named D
        hours: Math.floor(osps.summary / 3600),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    }

    const SummaryTime = () => {
        return(
            <p style={{fontSize: 20}}><b>{d.hours} ч. {d.minutes} мин. {d.seconds} с.</b></p>
        );
    }

    return(
        <div className="summary-card">
            <h4>Время проведенное на сервере<br/>за последний сеанс:</h4>
            <SummaryTime />
            <h4>На {s}-м сервере</h4>
        </div>
    );
}

const SummaryHours: React.FC<SummaryProps> = ({os}) => {
    const date = new Date(os * 1000);

    const d = { // Creating data of summary time from last time, named D
        days: Math.floor(os / 3600 / 24),
        hours: Math.floor(os / 3600) > 24 ? Math.floor(os / 3600) - 24 * Math.floor(os / 3600 / 24) : Math.floor(os / 3600),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    }

    const SummaryTime = () => {
        return(
            <p style={{fontSize: 20}}><b>{d.days} дней, {d.hours} ч. и {d.minutes} мин.</b></p>
        );
    }

    return(
        <div className="summary-card">
            <h4>Всего количество часов<br/>игры на проекте:</h4>
                <SummaryTime />
            <h4>Вау!</h4>
        </div>
    );
}

const WholeSummary: React.FC<SummaryProps> = ({osps, os, s, width,}) => {
    return(
        <div>
            <Divider variant="dashed" style={{borderColor: 'red'}}><h2>Статистика</h2></Divider>
            <div className="summary-content" style={width ? width > 800 ? {display: "flex"} : {display: "block", padding: "2%"} : {}}>
                <SummaryHoursPerLastEnter osps={osps} s={s}/>
                <SummaryHours os={os}/>
            </div>
        </div>
    );
}


//const PersonalData: React.FC

export {WholeSummary};
