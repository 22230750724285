import React from "react";
import {SummaryProps} from "./summary";
import "../../css/summary.css";
import { Tooltip } from "antd";


const OnlineTable: React.FC<SummaryProps> = ({fs}) => {
    const timeZone = 'Europe/Moscow';

    const gettingDate = (date: any) => {
        date = new Date(date * 1000);
        const time = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
        const dd = {
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            date: date.getDate(),
            time,
        }
        return dd.date+'.'+dd.month+'.'+dd.year+', '+dd.time;
    }

    const gettingTime = (time: any) => {
        const newTime = new Date(time * 1000);

        const d = { // Creating data of summary time from last time, named D
            minutes: newTime.getMinutes(),
            seconds: newTime.getSeconds(),
        }
        return `${Math.floor(time / 3600)} ч. ${d.minutes} мин. ${d.seconds.toString().padStart(2,'0')} c.`;
    };

    return(
        <div className="online-table-box">
            <table className="online-table" >
                <thead>
                    <tr>
                        <td><h3>Время захода</h3></td>
                        <td><h3>Сервер, на который заходил</h3></td>
                        <td><h3>Суммарное проведённое время</h3></td>
                        <td><h3>Время выхода</h3></td>
                    </tr>
                </thead>
                <tbody>
                    {fs.data.map((row: any, index: any, arr: any) => (
                        <tr key={index}>
                        <td>
                        <Tooltip title={`${new Date((row.connect_time) * 1000).toLocaleString('ru-RU', {
                            timeZone: timeZone,
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false
                          })}`}>
                            {`${gettingDate(row.connect_time)}`}
                          </Tooltip>
                        </td>
                        <td className="column">{row.server_id}</td>
                        <td className="column">{`${gettingTime(row.summary)}`}</td>
                        <td>
                          <Tooltip title={`${new Date((row.connect_time + row.summary) * 1000).toLocaleString('ru-RU', {
                            timeZone: timeZone,
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false
                          })}`}>
                            {row.server_id === fs.data.server_id && arr.length -1 === index ? 'На сервере' : `${gettingDate(row.connect_time+row.summary)}`}
                          </Tooltip>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export {OnlineTable};