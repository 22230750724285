import React, {useState} from "react";
import dayjs, { Dayjs } from 'dayjs';
import { Button, Form, Input, DatePicker, InputNumber } from "antd";
import _ from "lodash";
import { Stuff } from "../../types/interfaces";
import { CollapseElement } from "./collapse";

const { RangePicker } = DatePicker;

const ItemsEdit: React.FC<{newAdminData: any, setNewAdminData: any}> = ({newAdminData, setNewAdminData}) => {
    const [newRepr, setNewRepr] = useState<{
        date: string | string[],
        reason: string,
        points: number | null,
    }>({
        date: '',
        reason: '',
        points: 0,
    })
    const [newDepartment, setNewDepartment] = useState<{
        department: string;
        dates: [Dayjs | null, Dayjs | null];
    }>({
        department: '',
        dates: [null, null], // Начальные значения
    });

    const [newDepartmentHistory, setNewDepartmentHistory] = useState<{
        department: string;
        dates: [Dayjs | null, Dayjs | null];
    }>({
        department: '',
        dates: [null, null], // Начальные значения
    });

    const handleChange = (key: string, value: any, parent: string) => {
        setNewAdminData((prevState: any) => {
            const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
            _.set(updatedData, `${parent}.${key}`, value); // Обновляем вложенное поле
            return updatedData;
        });
    };

    const addRepr = (newRepr: {date: string | string[], reason: string,points: number | null}) => {
        setNewAdminData((prevState: any) => {
            const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
            _.set(updatedData, 'stuffData.reprimands', [
                ..._.get(updatedData, 'stuffData.reprimands', []), 
                newRepr
            ]); 
            return updatedData;
        });
    }

    const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
        if (dates) {
            setNewDepartment({ ...newDepartment, dates });
        } else {
            setNewDepartment({ ...newDepartment, dates: [null, null] });
        }
    };

    const handleDateChangeHistory = (dates: [Dayjs | null, Dayjs | null] | null) => {
        if (dates) {
            setNewDepartmentHistory({ ...newDepartment, dates });
        } else {
            setNewDepartmentHistory({ ...newDepartment, dates: [null, null] });
        }
    };

    const addDepartment = (newDepartment: { department: string; dates: any[] }) => {
        setNewAdminData((prevState: any) => {
            const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
            _.set(updatedData, 'stuffData.projectWork.departments', [
                ..._.get(updatedData, 'stuffData.projectWork.departments', []), 
                newDepartment
            ]); 
            return updatedData;
        });
    };

    const addDepartmentHistory = (newDepartment: { department: string; dates: any[] }) => {
        setNewAdminData((prevState: any) => {
            const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
            _.set(updatedData, 'stuffData.workHistory.departments', [
                ..._.get(updatedData, 'stuffData.workHistory.departments', []), 
                newDepartment
            ]); 
            return updatedData;
        });
    };

    const dateFormat = (dates: any) => {
        return dates.map((date: any) => date ? dayjs(date) : null);
    }

    const items = [
        {
            key: 0,
            label: "Личная информация",
            children: (
                <div>
                    <Form.Item label="Имя">
                        <Input
                            value={newAdminData?.stuffData?.personalInfo?.name || ''}
                            onChange={(e) => handleChange('name', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                    <Form.Item label="Discord">
                        <Input
                            value={newAdminData?.stuffData?.personalInfo?.discord || ''}
                            onChange={(e) => handleChange('discord', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                    <Form.Item label="Telegram">
                        <Input
                            prefix="@"
                            value={newAdminData?.stuffData?.personalInfo?.telegram || ''}
                            onChange={(e) => handleChange('telegram', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                </div>
            ),
        },
        {
            key: 1,
            label: "Работа на проекте",
            children: (
                <div>
                    <Form.Item>
                        <Input style={{margin: '3px'}}
                            placeholder="Название отдела: позиция"
                            value={newDepartment.department}
                            onChange={(e) => setNewDepartment({ ...newDepartment, department: e.target.value })}
                        />
                        <RangePicker style={{margin: '3px'}}
                            value={newDepartment.dates}
                            placeholder={['', 'До сих пор']}
                            allowEmpty={[false, true]}
                            onChange={(dates) => handleDateChange(dates as [Dayjs | null, Dayjs | null] | null)}
                        />
                        <Button style={{margin: '7px 3px 3px 3px'}} onClick={() => addDepartment(newDepartment)}>Добавить отдел</Button>
                    </Form.Item>
                    {newAdminData?.stuffData?.projectWork?.departments?.map((dep: any, index: number) => (
                        <Form.Item key={index} label={`Отдел ${dep.department}`}>
                            <Input style={{margin: '3px'}}
                                value={dep.department}
                                onChange={(e) => handleChange('department', e.target.value, `stuffData.projectWork.departments[${index}]`)}
                            />
                            <RangePicker style={{margin: '3px'}}
                                value={dateFormat(dep.dates) || []}
                                placeholder={['', 'До сих пор']}
                                allowEmpty={[false, true]}
                                onChange={(dates) => handleChange('dates', dates, `stuffData.projectWork.departments[${index}]`)}
                            />
                        </Form.Item>
                    ))}
                </div>
            ),
        },
        {
            key: 2,
            label: "Предыдущие проекты",
            children: (
                <div>
                    <Form.Item>
                        <Input style={{margin: '3px'}}
                            placeholder="Название отдела прошлого рабочего места"
                            value={newDepartmentHistory.department}
                            onChange={(e) => setNewDepartmentHistory({ ...newDepartmentHistory, department: e.target.value })}
                        />
                        <RangePicker style={{margin: '3px'}}
                            value={newDepartmentHistory.dates}
                            onChange={(dates) => handleDateChangeHistory(dates as [Dayjs | null, Dayjs | null] | null)}
                        />
                        <Button style={{margin: '7px 3px 3px 3px'}} onClick={() => addDepartmentHistory(newDepartmentHistory)}>Добавить отдел</Button>
                    </Form.Item>
                    {newAdminData?.stuffData?.workHistory?.departments?.map((dep: any, index: number) => (
                        <Form.Item key={index} label={`Отдел ${dep.department}`}>
                            <Input style={{margin: '3px'}}
                                value={dep.department || ''}
                                onChange={(e) => handleChange('department', e.target.value, `stuffData.workHistory.departments[${index}]`)}
                            />
                            <RangePicker style={{margin: '3px'}}
                                value={dateFormat(dep.dates) || []}
                                onChange={(dates) => handleChange('dates', dates, `stuffData.workHistory.departments[${index}]`)}
                            />
                        </Form.Item>
                    ))}
                </div>
            ),
        },
        {
            key: 3,
            label: "О себе",
            children: (
                <div>
                    <Input value={newAdminData?.stuffData?.about || ''} onChange={(e) => handleChange('about', e.target.value, 'stuffData')}/>
                </div>
            ),
        },
        {
            key: 4,
            label: "Лучшие качества",
            children: (
                <div>
                    <Input value={newAdminData?.stuffData?.bestQualities || ''} onChange={(e) => handleChange('bestQualities', e.target.value, 'stuffData')}/>
                </div>
            ),
        },
        {
            key: 5,
            label: "Примечания",
            children: (
                <div>
                    <Input value={newAdminData?.stuffData?.notes || ''} onChange={(e) => handleChange('notes', e.target.value, 'stuffData')}/>
                </div>
            ),
        },
        {
            key: 6,
            label: "Выговоры",
            children: (
                <div>
                    <Form.Item>
                        <Input style={{margin: '3px'}}
                            placeholder="Причина выговора"
                            value={newRepr.reason}
                            onChange={(e) => setNewRepr({...newRepr, reason: e.target.value})}
                        />
                        <InputNumber style={{margin: '3px'}}
                            placeholder="Количество очков выговора"
                            value={newRepr.points}
                            onChange={(i) => setNewRepr({...newRepr, points: i})}
                        />
                        <DatePicker style={{margin: '3px'}} onChange={(i, d) => setNewRepr({...newRepr, date: d})} />
                        <Button style={{margin: '7px 3px 3px 3px'}} onClick={() => addRepr(newRepr)}>Добавить выговор</Button>
                    </Form.Item>
                    {newAdminData?.stuffData?.reprimands?.map((repr: any, index: number) => (
                        <Form.Item key={index} label={`Выговор №${index}`}>
                            <Input
                                placeholder="Причина выговора"
                                value={repr.reason}
                                onChange={(e) => handleChange('reason', e.target.value, `stuffData.reprimands[${index}]`)}
                            />
                            <InputNumber
                                placeholder="Количество очков выговора"
                                value={repr.points}
                                onChange={(e) => handleChange('points', e, `stuffData.reprimands[${index}]`)}
                            />
                            <DatePicker value={dayjs(repr.date)} onChange={(i, d) => handleChange('date', d, `stuffData.reprimands[${index}]`)} />
                        </Form.Item>
                    ))}
                </div>
            ),
        },
    ]

    return <CollapseElement items={items}/>
}

const getDateString = (date: any) => {
    return new Date(date).toLocaleString('ru-ru',{day: '2-digit', month: '2-digit', year: 'numeric'});
}

const Items: React.FC<{admin: Stuff}> = ({admin}) => {
    const items = [
        {
            key: 0,
            label: "Личная информация",
            children: <div>
                <p><b>Имя: </b> {admin.stuffData.personalInfo.name}</p>
                <p><b>Discord: </b> {admin.stuffData.personalInfo.discord}</p>
                <p><b>Telegram: </b> <a target="_blank" href={"https://t.me/" + admin.stuffData.personalInfo.telegram} style={{textDecoration: 'none'}}>{admin.stuffData.personalInfo.telegram}</a></p>
            </div>
        },
        {
            key: 1,
            label: "Работа на проекте",
            children: <div>
                <ul>
                    {admin.stuffData.projectWork.departments.map((dep: any, idx: number) => (
                        <li key={idx}>{dep.department}, <i>{getDateString(dep.dates[0])} &mdash; {dep.dates[1] === null ? 'До сих пор' : getDateString(dep.dates[1])}</i></li>
                    ))}
                </ul>
            </div>
        },
        {
            key: 2,
            label: "Предыдущие проекты",
            children: <div>
                <ul>
                    {admin.stuffData.workHistory?.departments.map((dep: any, idx: number) => (
                        <li key={idx}>{dep.department}, <i>{getDateString(dep.dates[0])} &mdash; {dep.dates[1] === null ? 'До сих пор' : getDateString(dep.dates[1])}</i></li>
                    ))}
                </ul>
            </div>
        },
        {
            key: 3,
            label: "О себе",
            children: <p>{admin.stuffData.about}</p>,
        },
        {
            key: 4,
            label: "Лучшие качества",
            children: <p>{admin.stuffData.bestQualities}</p>,
        },
        {
            key: 5,
            label: "Примечания",
            children: <p>{admin.stuffData.notes}</p>,
        },
        {
            key: 6,
            label: "Выговоры",
            children: <div>
                <ul>
                    {admin.stuffData.reprimands.map((rep: any, idx: number) => (
                        <li key={idx}>{rep.reason}, <i>{getDateString(rep.date)}</i>. Очков: {rep.points}</li>
                    ))}
                </ul>
            </div>
        },
    ]

    return <CollapseElement items={items}/>
}

export {ItemsEdit, Items};
