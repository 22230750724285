import React, { useEffect, useState } from "react";
import { Button, Divider, Layout, Popover, theme } from "antd";

import "../css/servers.css";
import ServerModal from "./server-components/server-modal";
import { ServersTableprops } from "../small-components/interfaces";

import config from "../config.json";
import { api } from "../const";

const href = config.cfg.local ? "https://db.scpubtroll.net/" : "https://admin.scpubtroll.net/";

const ServersPage: React.FC = () => {
  const [servers, setServers] = useState([]);
  const [roles, setRoles] = useState([]);
  const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();

  const height = window.innerHeight;

  useEffect(()=>{
    const webSocket = new WebSocket('wss://scpubtroll.net:3002');

    webSocket.onmessage = (event) => {
      if(event.data !== "not known ap_type")
      {
        const res = JSON.parse(event.data);
        switch (res.res_type)
        {
          case "servers":
            setServers(res.res_data);
            console.log(res.res_data);
            break;
          }
      }
    };

    return () => {
      webSocket.close();
    };
  },[]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const resData = (await api.get('/api/admin/roles')).data;
        if (resData) {
          resData.sort((a:any, b:any) => a.id - b.id)
          setRoles(resData);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchRoles();
  },[servers]);

  return(
    <>
      <Layout
        style={{ borderRadius: borderRadiusLG, background: colorBgContainer, height: height - 200 }}
      >
        <div className="content-of-servers" style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column', padding: "50px"}}>
          <h1 style={{width: "100%", textAlign: "center"}}>Сервера и частичное управление ими</h1>
          <Divider />
          <ServersTable servers={servers} roles={roles}/>
        </div>
      </Layout>
    </>
  )
};

const ServersTable: React.FC<ServersTableprops> = ({servers, roles}) => {
  
  const GetColor = (userrole: any, roles: any) => {
    if(roles)
    {
      let color = "black";
      roles.map((role: any) => {
        if(role.name === userrole)
        {
          color = role.color;
        }
      });
      return color;
    }
  }

  return(
    <>
    <table className="servers-table">
      <thead>
        <tr>
          <th colSpan={4}>Сервер</th>
          <th colSpan={2}>Посчитанные данные</th>
          <th colSpan={2}>Действия</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Название</th>
          <th>Статус</th>
          <th>Карта</th>
          <th>Кол-во троллейбусов</th>
          <th>Кол-во игроков</th>
          <th>Подключение</th>
          <th>Контроль</th>
        </tr>
      </thead>
      <tbody>
        {roles && servers && servers.length > 0 ? (
          servers.map((server: any, index: number) => (
            <tr key={index}>
              <td>{server.server_id}</td>
              <td>{server.title}</td>
              <td>{server.status === 1 ? "Включён" : "Выключен"}</td>
              <td>{server.map}</td>
              <td>{server.trolls.length}</td>
              <td>
                {server.users ? (
                  <Popover
                    content={server.users.map((user: any, index: number) => (
                      <p key={index}><span style={{color: `${GetColor(user.roles.serverrole, roles)}`}}>{user.nickname}, </span>
                        <a
                          target="_blank"
                          href={href+"user?steamid="+user.steamid}
                        >
                          {user.steamid}
                        </a>
                      </p>
                    ))}
                    title="Игроки на сервере"
                  >
                    {server.users ? server.users.length : "0"}
                  </Popover>
                ): "0"}
              </td>
              <td>{server.ip ? <Button href={"steam://connect/"+server.ip}>Подключиться</Button> : "Подключение не доступно"}</td>
              <td><ServerModal key={index} server={server}/></td>
            </tr>
          ))
        ):(
          <tr>
            <td className="nodata-td" colSpan={8}>Нету данных :с (womp-womp)</td>
          </tr>
        )}
      </tbody>
    </table>
    </>
  )
}

export default ServersPage;