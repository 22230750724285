import { Button, Modal } from "antd";
import React from "react";

interface prps {
    open: {setOpen: any, open: boolean},
    header: string,
    children: React.ReactNode,
}

const ModalElement: React.FC<prps> = ({open, header, children}) => {
    return(
        <>
        <Button onClick={() => open.setOpen(true)}>Открыть</Button>
        <Modal
            open={open.open}
            onOk={() => open.setOpen(false)}
            onCancel={() => open.setOpen(false)}
            styles={{mask: {backdropFilter: 'blur(5px)'}}}
            centered
            title={header}
        >
            {children}
        </Modal>
        </>
    )
}

export {ModalElement};
