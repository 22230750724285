import React, { useEffect, useState } from "react";
import { api } from "../const";
import { Button, Divider, Layout, theme } from "antd";
import config from "../config.json";

import "../css/servers.css";
import { UsersTableProps } from "../small-components/interfaces";
import { SearchInput } from "./roles-components/roles-table";
import { NavigationLink } from "../big-components/navbar/navlink";

const url = config.cfg.local ? "https://db.scpubtroll.net/" : "https://admin.scpubtroll.net/";

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState([]);
  const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();

  const height = window.innerHeight;

    const fetchData = async () => {
        const data = (await api.get("/users/")).data;
        setUsers(data);
    }

  useEffect(()=>{
    fetchData();
  },[]);

  return(
    <>
      <Layout
        style={{ borderRadius: borderRadiusLG, background: colorBgContainer, height: height - 200 }}
      >
        <div className="content-of-servers" style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column', padding: "50px"}}>
          <h1 style={{width: "100%", textAlign: "center"}}>Игроки</h1>
          <Divider />
          <SearchInput sData={setUsers} fetchUsers={fetchData}/>
          <div style={{width: "100%", height: 500, overflow: "auto"}}>
            {users ? 
            <UsersTable users={users}/>
            :
            <span>No data</span>
            }
          </div>
        </div>
      </Layout>
    </>
  )
};

const UsersTable: React.FC<UsersTableProps> = ({users}) => {
    const [roles, setRoles] = useState([{
        name: '',
        access: [''],
        id: 100,
        admin: false,
    }]);

    const fetchRoles = async () => {
        try {
            const resData = (await api.get('/api/admin/roles')).data;
            if (resData) {
                resData.sort((a:any, b:any) => a.id - b.id)
                setRoles(resData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {fetchRoles();},[users])
  return(
    <div className="roles-table-box table-box">
    <table className="roles-table admins-table table">
      <thead>
        <tr>
            <td><b>Никнейм</b></td>
            <td><b>SteamID</b></td>
            <td><b>Роль сервера</b></td>
            <td><b>Роли сайта</b></td>
            <td><b>Действия</b></td>
        </tr>
      </thead>
      <tbody>

        {roles ? roles.map((role) => 
                            
        users && users.length > 0 ? (
          users.map((user: any, index: number) => (role.name === user.roles.serverrole  &&
            <tr key={index}>
                <td>{user.nickname}</td>
                <td>{user.steamid}</td>
                <td>{user.roles.serverrole}</td>
                <td>{user.roles.siteroles.map((role: any, index: any, arr: any)=> {return index !== arr.length -1 ? role+', ' : role})}</td>
                <td><Button><NavigationLink outLink={false} link={`/user?steamid=${user.steamid}`} text="Перейти в профиль"/></Button></td>
            </tr>
          ))
        ):(
          <tr>
            <td className="nodata-td" colSpan={8}>Нету данных :с (womp-womp)</td>
          </tr>
        )) : <p>oops</p>}
      </tbody>
    </table>
    </div>
  )
}

export default UsersPage;