import React from "react";
import { Route, Routes } from "react-router-dom";

import OnlinePage from "../pages/online-page";
import ProfilePage from "../pages/profile-page";
import RolesPage from "../pages/roles-page";
import ServersPage from "../pages/servers-page";
import UsersPage from "../pages/users-page";
import UserProfilePage from "../pages/user-profile-page";
import LogsPage from "../pages/logs-page";
import NewsPage from "../pages/news-page";
import StuffPage from "../pages/stuff-page";


interface props {
    userData: any;
    t: any;
    accesses: any;
}

export const RoutesRouter: React.FC<props> = ({userData, t, accesses}) => {
    const [mount, setMount] = React.useState(false);
    React.useEffect(() => {
        const mounting = () => {
          if (accesses.online) {
            setMount(true);
          }
        };
        mounting(); // Выполнить один раз при монтировании компонента
        const intervalId = setInterval(mounting, 5000); // 5000 миллисекунд = 5 секунд
      
        return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
      }, [accesses, mount]);
    return(
        <>
        <Routes>
            <Route path="/" element={<ProfilePage data={userData}/>} />
            <Route path="/online" element={<OnlinePage data={userData}/>} />
            <Route path="/roles" element={<RolesPage data={userData}/>} />
            <Route path="/servers" element={<ServersPage/>} />
            <Route path="/users" element={<UsersPage/>} />
            <Route path="/user" element={<UserProfilePage data={userData}/>} />
            <Route path="/logs" element={<LogsPage/>} />
            <Route path="/newspost" element={<NewsPage/>} />
            <Route path="/stuff" element={<StuffPage />}></Route>
        </Routes>
        </>
    );
}