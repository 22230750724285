import React, { useEffect, useMemo, useState } from "react";
import { RoutesRouter } from "./router";
import { useTranslation } from 'react-i18next';
import { Button, Layout, Menu, } from 'antd';
import Footer from "../small-components/footer";

import scpublogo from "../images/scpublogo.png";

import "../css/canvas.css";
import "../css/app.css";
import "../css/users.css";

import { api, url } from "../const";
import { NavigationLink } from "../big-components/navbar/navlink";
import { AxiosError } from "axios";

const { Header, Content } = Layout;

type UserData = {
  nickname?: string;
  steamid?: string;
  avatar?: string;
  link?: string;
  roles?: any;
  reverse_id?: number;
  connect_time?: any;
  server_id?: number;
};


interface AccessesType {
  [key: string]: boolean; // Позволяет любому ключу типа string быть значением типа boolean
}

type windowprps = {
  userData: any;
}

const MainWindow: React.FC<windowprps> = ({userData}) => {
  const { t } = useTranslation();
  const height = window.innerHeight - 80;
  const [items, setItems] = useState([{
    key: 0,
    label: <NavigationLink link="/" outLink={false} text="Профиль"/>
  },]);
  const [mount, setMount] = useState(false);
  const [updates, setUpdates] = useState<AccessesType>({
    "roles-page": false,
    "rules": false,
    "users-page": false,
    "online-page": false,
    "servers-page": false,
    "logs-page": false,
    "newspost": false,
  });

  

  useEffect(() => {
    const accessesArr = ["roles-page", "rules", "online-page", "users-page", "servers-page", "logs-page", "newspost"];
  
    const fetchAccess = async () => {
      try {
        // Выполняем все запросы параллельно
        const requests = accessesArr.map(access =>
          api.get(`/api/admin/access?reqaccess=${access}`, )
        );

        const responses = await Promise.all(requests);

        // Создаем новый объект для обновлений
        const newUpdates: AccessesType = {};

        responses.forEach((res, index) => {
          if (res.data === true) {
            newUpdates[accessesArr[index]] = true;
          } else {
            newUpdates[accessesArr[index]] = false; // По умолчанию false, если не 200
          }
        });

        // Обновляем состояние updates
        setUpdates(newUpdates);
        setItems(prevItems => {
          const newItems = [...prevItems];
          
          if (newUpdates["online-page"] && !newItems.find(item => item.key === 1)) {
            newItems.push({ key: 1, label: <NavigationLink link="/online" outLink={false} text="Персонал" /> });
          }
          if (newUpdates["roles-page"] && !newItems.find(item => item.key === 2)) {
            newItems.push({ key: 2, label: <NavigationLink link="/roles" outLink={false} text="Роли" /> });
          }
          if (newUpdates["servers-page"] && !newItems.find(item => item.key === 3)) {
            newItems.push({ key: 3, label: <NavigationLink link="/servers" outLink={false} text="Сервера" /> });
          }
          if (newUpdates["logs-page"] && !newItems.find(item => item.key === 4)) {
            newItems.push({ key: 4, label: <NavigationLink link="/logs" outLink={false} text="Логи" /> });
          }
          if (newUpdates["users-page"] && !newItems.find(item => item.key === 5)) {
            newItems.push({ key: 5, label: <NavigationLink link="/users" outLink={false} text="Пользователи" /> });
          }
          if (newUpdates["newspost"] && !newItems.find(item => item.key === 6)) {
            newItems.push({ key: 6, label: <NavigationLink link="/newspost" outLink={false} text="Публикация новостей" />})
          }
    
          return newItems;
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchAccess(); // Вызываем addingItems после завершения fetchAccess
  }, []);

  useEffect(() => {
    setMount(true);
  },[items])

  return(
    <Layout style={{height: height}}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" /><span></span>
        {mount ? <Menu
          theme="dark"
          mode="horizontal"
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        /> : <p>Нихт доступе</p>}
      </Header>
      <Content style={{ padding: '0 48px', height: "100vh" }}>
        <div
          style={{
            height: "100%",
            padding: 24,
          }}
        >
          {updates ? <RoutesRouter userData={userData} t={t} accesses={updates}/> : <p>a</p>}
        </div>
      </Content>
    </Layout>
  );
}

const App: React.FC = () => {
    const [userData, setUserData] = useState<UserData>({
      nickname: 'a',
      steamid: 'a',
      avatar: 'a',
      link: 'a',
      roles: { serverrole: 'a', siteroles: ['a'] },
      reverse_id: 0,
      server_id: 0
    });
    const height = window.innerHeight - 80;
    const [access, setAccess] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get('/auth/admin/', );
          setDisabled(false);
          if (response.data.nickname) {
            setUserData(response.data);
            setAccess(true);
          }
          
        } catch (error: AxiosError | any) {
          if(error.response.status === 503)
          {
            setDisabled(true);
          }
          setAccess(false);
        }
      };
  
      fetchData(); // Инициализируем вызов данных при монтировании компонента
      const interval = setInterval(() => fetchData(), 10000);

      return () => {
        clearInterval(interval);
      }
    }, []);

    return(
        <div className="app">
          {access ? (
            <MainWindow userData={userData}/>
          ):(
            <div style={{backgroundColor: 'rgb(40,40,40)', height: height, display: 'flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center', flexDirection: 'column'}}>
              <img width={256} src={scpublogo} alt="aboba"/>
              <h1 style={{verticalAlign: 'middle', textAlign: 'center', height: '50px', color:'white'}}>Войдите в систему:</h1>
              <Button disabled={disabled} href={url + "/auth/steam/admin"} ghost style={{color: "white", textDecoration: "none"}}>Войти через стим</Button>
              {disabled ? <p style={{color: "white"}}>Сайт временно недоступен</p> : <></>}
            </div>
          )}
          
          <Footer description="SC-Pub AdminSite. Версия 0.5.0, выполнил и сделал Андрей (Coelho_)"/>
        </div>
    );
}

export default App
