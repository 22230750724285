import React, {useEffect, useState} from "react";
import dayjs, { Dayjs } from 'dayjs';
import { Button, Form, Input, DatePicker } from "antd";
import _ from "lodash";



import { CollapseElement } from "./collapse";
import { ModalElement } from "./modal";

import { api } from "../../../const";

import { Stuff } from "../../types/interfaces";
import { itemsEdit } from "./collapse-items";
import { ItemType } from "antd/es/menu/interface";


const StuffModal: React.FC<{admin: Stuff}> = ({admin}) => {
    const [newAdminData, setNewAdminData] = useState<Stuff>(admin);
    const [editMode, setEditMode] = useState(true);
    const [open, setOpen] = useState(false);

    const sendNewData = async() => {
        try {
            console.log(newAdminData);
        } catch (err) {
            console.log(err);
        }
    }

    return(
        <>
        <ModalElement open={{open: open, setOpen: setOpen}} header={`${admin.name ? admin.name : admin.steamid} (${admin.nickname}${admin.name ? ', ' + admin.steamid : ''})`}>
            {editMode ?
            <>
                <h5>{admin.stuffData.managerAndDate?.manager.nickname}</h5>
                <h6>{admin.stuffData.managerAndDate?.date ? new Date(admin.stuffData.managerAndDate?.date).toLocaleString('ru-ru',{day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}) : "Изменений не было / Новый сотрудник"}</h6>
                <Form
                    name="StuffModal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    onFinish={sendNewData}
                    autoComplete="off"
                >
                    <CollapseElement items={itemsEdit(newAdminData,setNewAdminData)}/>
                </Form>
            </>
            :
            <>

            </>
            }
        </ModalElement>
        </>
    )
}

const StuffItems: React.FC<{admins: Stuff[]}> = ({admins}) => {
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const [roles, setRoles] = useState([{
        name: '',
        access: [''],
        id: 100,
        admin: false,
    }]);

    const fetchRoles = async () => {
        try {
            const resData = (await api.get('/api/admin/roles')).data;
            if (resData) {
                resData.sort((a:any, b:any) => a.id - b.id)
                setOptions(resData.map((role: any) => ({
                    value: role.name,
                    label: role.name,
                })));
                setRoles(resData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {fetchRoles();},[admins]);

    const getItems = (newAdminData: Stuff) => {
        if(!newAdminData.stuffData)
        {
            newAdminData = {
                ...newAdminData, stuffData: {
                    personalInfo: {
                        age: 0,
                        name: '',
                        steamid: '',
                        discord: '',
                        telegram: ''
                    },
                    projectWork: {
                        departments: [],
                        positions: [],
                    },
                    projectWorkEarlier: [{
                        departments: [],
                        positions: [],
                    }],
                    workHistory: [{
                        departments: [],
                        positions: [],
                    }],
                    about: '',
                    bestQualities: '',
                    notes: '',
                    reprimands: [],
                    thanks: [],
                }
            }
        }
        return newAdminData;
    }

    
    return(
        <>
            <table className="roles-table admins-table table">
                <thead>
                    <tr>
                        <td rowSpan={2}>#</td>
                        <td colSpan={2}><b>Информация</b></td>
                        <td colSpan={2}><b>Роли</b></td>
                        <td rowSpan={2}><b>Действия</b></td>
                    </tr>
                    <tr>
                        <td><b>Никнейм</b></td>
                        <td><b>SteamID</b></td>
                        <td><b>Роль сервера</b></td>
                        <td><b>Роли сайта</b></td>
                    </tr>
                </thead>
                <tbody>
                    {admins.map((user: any, index: number)=>
                        <tr key={index}>
                            <td>{++index}</td>
                            <td>{user.nickname}</td>
                            <td>{user.steamid}</td>
                            <td>{user.roles.serverrole}</td>
                            <td>{user.roles.siteroles.map((role: any, index: any, arr: any)=> {return index !== arr.length -1 ? role+', ' : role})}</td>
                            <td><StuffModal admin={getItems(user)}/></td>
                        </tr>
                    )}
                </tbody>
            </table>
            <span style={{width: '100%', textAlign: 'end', marginTop: '10px'}}><b>Всего персонала:</b> {admins.length === 0 ? "Считается..." : admins.length}</span>
        </>
    );
}

export default StuffItems;
