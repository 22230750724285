import { Button, Modal } from "antd";
import React from "react";

interface prps {
    open: {setOpen: any, open: boolean},
    header: string,
    footer?: any,
    children: React.ReactNode,
}

const ModalElement: React.FC<prps> = ({open, header, children, footer}) => {
    return(
        <>
        <Button onClick={() => open.setOpen(true)}>Открыть</Button>
        <Modal
            open={open.open}
            onOk={() => open.setOpen(false)}
            onCancel={() => open.setOpen(false)}
            styles={{mask: {backdropFilter: 'blur(5px)'}}}
            centered
            
            title={header}
            footer={footer ? footer : <span><Button onClick={() => open.setOpen(false)}>Закрыть</Button></span>}
        >
            <div style={{overflowX: 'auto', maxHeight: 600, width: '100%'}}>
                {children}
            </div>
        </Modal>
        </>
    )
}

export {ModalElement};
