import React, {useEffect, useState} from "react";
import { Layout, List, Input, Form, Button, Image, Flex, Divider, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { api } from "../const";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NewsPage: React.FC = () => {
    const [na, sna] = useState<{header: string, description: string, media?: string[]}>({
        header: '',
        description: '',
        media: [],
    });
    const [tempPhoto, setTempPhoto] = useState("");
    const [api2, contextHolder] = notification.useNotification();


    const openNotification = (msg: string, desc: string, type: NotificationType) => () => {
        api2[type]({
          message: msg,
          description: desc,
          showProgress: true,
          pauseOnHover: true,
          placement: 'bottom'
        });
    };


    const handleChange = (key: string, value: any) => {
        sna(prevState => ({ ...prevState, [key]: value }));
    };

    const handleSend = async () => {
        try {
            const res = await api.post("/news/post", {annon: na});
            openNotification("Новость отправлена!","Новость успешно отправлена на все сайты. Ура, поздравляю!)", "success");
        } catch (err) {
            console.log(err);
            openNotification("Упс-с...","...кажется произошла ошибка... походу придётся вручну(((((", "error");
        }
    }

    const photosChange = (photo: string) => {
        let nna = {
            header: na.header,
            description: na.description,
            media: na.media
        }

        if(na.media && na.media.length <= 4)
        {
            if(na.media.includes(photo))
            {
                const index = na.media.indexOf(photo);
                
                if(index > -1)
                {
                    nna.media?.splice(index, 1);
                }
            } else {
                nna.media?.push(photo);
            }
        
            sna(nna);
        } else {
            openNotification("Ограничение", "Прости, но ты не можешь использовать больше 4 фоток ((((", "error");
        }
    }

    const items = [
        {
            label: "Заголовок",
            name: 'header',
            children: <Input value={na?.header} onChange={(e) => handleChange('header', e.target.value)}/>,
            req: true
        },
        {
            label: "Описание",
            name: 'description',
            children: <TextArea value={na?.description} onChange={(e) => handleChange('description', e.target.value)}/>,
            req: true
        },
        {
            label: 'Фотки',
            name: 'media',
            children: <div>
                <span><Input style={{width: '75%'}} onChange={(e) => setTempPhoto(e.target.value)}/><Button style={{marginLeft: "5%", width: 'auto'}} onClick={() => photosChange(tempPhoto)}>Добавить</Button></span>
            </div>,
            req: false
        }
    ]

    return(
        <Layout className="news-post-page">
            {contextHolder}
            <h1 style={{textAlign: 'center', width: '100%'}}>Публикация новостей на ресурсы проекта</h1>
            <Divider variant="dashed" style={{borderColor: 'red'}}/>
            <Flex style={{width: '70%', height: '100%', margin: 'auto', flexDirection: 'column'}}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    style={{width: '70%', margin: 'auto' }}
                    onFinish={handleSend}
                >
                    {items.map((item: {label: string, name: string, children: any, req: boolean}, index: number) => (
                        <Form.Item key={index} label={item.label} name={item.name} rules={item.req ? [{ required: true, message: '' }] : []}>
                            {item.children}
                        </Form.Item>
                    ))}
                    <Form.Item label={null}>
                        <Button type="primary" htmlType="submit">
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
                <Divider variant="dotted" style={{ borderColor: '#7cb305' }}>Фотографии</Divider>
                <div className="photos" style={{width: '60%', margin: 'auto'}}>
                    <Flex wrap gap="small" justify="space-around">
                    {na.media?.map((photo: string, index: number) => (
                        <div key={index} style={{display: 'flex', flexDirection: 'column', gap: '3px', alignItems: 'center', justifyContent: 'center'}}>
                            <Image width={200} src={photo}/>
                            <Button onClick={() => photosChange(photo)}>Удалить</Button>
                        </div>
                    ))}
                    </Flex>
                </div>
            </Flex>
        </Layout>
    )
}

export default NewsPage;
