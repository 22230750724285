import React, {useState} from "react";
import dayjs, { Dayjs } from 'dayjs';
import { Button, Form, Input, DatePicker } from "antd";
import _ from "lodash";

const { RangePicker } = DatePicker;

const itemsEdit = (newAdminData: any, setNewAdminData: any) => {
    const [newDepartment, setNewDepartment] = useState<{
            department: string;
            dates: [Dayjs | null, Dayjs | null];
        }>({
            department: '',
            dates: [null, null], // Начальные значения
        });
    
        const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
            if (dates) {
                setNewDepartment({ ...newDepartment, dates });
            } else {
                setNewDepartment({ ...newDepartment, dates: [null, null] });
            }
        };
    
        const handleChange = (key: string, value: any, parent: string) => {
            setNewAdminData((prevState: any) => {
                const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
                _.set(updatedData, `${parent}.${key}`, value); // Обновляем вложенное поле
                return updatedData;
            });
        };
    
        const addDepartment = (newDepartment: { department: string; dates: any[] }) => {
            setNewAdminData((prevState: any) => {
                const updatedData = _.cloneDeep(prevState); // Создаем глубокую копию состояния
                _.set(updatedData, 'stuffData.projectWork.departments', [
                    ..._.get(updatedData, 'stuffData.projectWork.departments', []), 
                    newDepartment
                ]); 
                return updatedData;
            });
        };

    return [
        {
            key: 0,
            label: "Личная информация",
            children: (
                <div>
                    <Form.Item label="Имя">
                        <Input
                            value={newAdminData?.stuffData?.personalInfo?.name || ''}
                            onChange={(e) => handleChange('name', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                    <Form.Item label="Discord">
                        <Input
                            value={newAdminData?.stuffData?.personalInfo?.discord || ''}
                            onChange={(e) => handleChange('discord', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                    <Form.Item label="Telegram">
                        <Input
                            value={newAdminData?.stuffData?.personalInfo?.telegram || ''}
                            onChange={(e) => handleChange('telegram', e.target.value, 'stuffData.personalInfo')}
                        />
                    </Form.Item>
                </div>
            ),
        },
        {
            key: 1,
            label: "Работа на проекте",
            children: (
                <div>
                    <Form.Item>
                        <Input
                            placeholder="Название нового отдела"
                            value={newDepartment.department}
                            onChange={(e) => setNewDepartment({ ...newDepartment, department: e.target.value })}
                        />
                        <RangePicker
                            value={newDepartment.dates}
                            onChange={(dates) => handleDateChange(dates as [Dayjs | null, Dayjs | null] | null)}
                        />
                        <Button onClick={() => addDepartment(newDepartment)}>Добавить отдел</Button>
                    </Form.Item>
                    {newAdminData?.stuffData?.projectWork?.departments?.map((dep: any, index: number) => (
                        <Form.Item key={index} label={`Отдел ${index + 1}`}>
                            <Input
                                value={dep.department || ''}
                                onChange={(e) => handleChange('department', e.target.value, `stuffData.projectWork.departments[${index}]`)}
                            />
                            <RangePicker
                                value={dep.dates || []}
                                onChange={(dates) => handleChange('dates', dates, `stuffData.projectWork.departments[${index}]`)}
                            />
                        </Form.Item>
                    ))}
                </div>
            ),
        },
    ]
}

export {itemsEdit};
