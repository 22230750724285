import React from "react";
import { Collapse } from "antd";
import type { CollapseProps } from 'antd';

interface collapse {
    items: CollapseProps['items'],
}

const CollapseElement: React.FC<collapse> = ({items}) => {
    return(
        <Collapse items={items}/>
    )
};

export {CollapseElement};
