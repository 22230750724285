import { Divider, Layout } from "antd";
import React, {useEffect, useState} from "react";
import StuffItems from "../big-components/modalsAndElems/stuff";
import { api, crypt } from "../const";

const StuffPage: React.FC = () => {
    const [admins, setAdmins] = useState([]);


    const fetchAdmins = async () => {
        try{
            const res = await api.get('/stuff/');
            const data = res.data;

            const decryptedData = JSON.parse(crypt.Decrypt(data.data));

            setAdmins(decryptedData);
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {fetchAdmins();}, []);

    return(
        <Layout className="news-post-page">
            <h1 style={{textAlign: 'center', width: '100%'}}>Персонал проекта</h1>
            <Divider variant="dashed" style={{borderColor: 'red'}}/>
            
            <StuffItems admins={admins}/>
        </Layout>
    )
}

export default StuffPage;
