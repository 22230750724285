import React, {useState} from "react";
import { Button, Flex, Input, Modal, message } from 'antd';
import { modal } from "../../small-components/interfaces";
import { api, mask } from "../../const";

const ServerModal: React.FC<modal> = ({server}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [mapToChange, setMapToChange] = useState('');


    const changeMap = async () => {
        try{
            setLoading(true);
            const command = "changelevel "+mapToChange;
            const res = await api.post('/api/servers/control/post/command?id=1', {command}, );
            if(res.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'Производится смена карты',
                });              
            }
        } catch(err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const changeState = async (signal: string) => {
        try{
            setLoading(true);
            const res = await api.post('/api/servers/control/post/state?id=1', {signal}, );
            if(res.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'Производится смена состояния сервера',
                });              
            }
        } catch(err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (e: any) => {
        setMapToChange(e.target.value);
    }

    return(
        <>
        {contextHolder}
        <Button type="primary" disabled>Контроль сервера</Button>
        <Modal
            title={server.title}
            centered
            open={open}
            onCancel={()=> setOpen(false)}
            footer={
                <Button onClick={() => setOpen(false)}>Закрыть контроль</Button>
            }
            width={700}
            styles={mask}
        >
            <Flex gap="middle" justify="center" align="center" vertical>
                <Flex gap="middle" justify="center" align="center">
                    <Button onClick={() => changeState("start")} color="primary" variant="solid">Включить</Button>
                    <Button onClick={() => changeState("restart")} color="default" variant="solid" style={{backgroundColor: 'orange'}}>Перезапустить</Button>
                    <Button onClick={() => changeState("stop")} color="danger" variant="solid">Выключить</Button>
                </Flex>
                <Input.Search loading={loading} placeholder={server.map} enterButton={<Button loading={loading} color="default" size="small" onClick={() => changeMap()}>Отправить</Button>} onChange={(e) => handleChange(e)}/>
            </Flex>
        </Modal>
        </>
    )
}

export default ServerModal;
