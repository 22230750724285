import axios from "axios";
import config from "./config.json";
import CryptoJS from "crypto-js";

export const url = config.cfg.local ? config.links.locallink : config.links.publiclink;

const SECRET_KEY = "PfbaPwfzN0qG4wOViLiZGQuz9kxvX5p1";

export const api = axios.create({
    baseURL: url+'/', // change to your desired hostname and port
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
});

class Crypting {
  Encrypt = (dataToEncrypt: string) => {
      return CryptoJS.AES.encrypt(dataToEncrypt, SECRET_KEY).toString();
  }

  Decrypt = (dataToDecrypt: string) => {
      const bytes = CryptoJS.AES.decrypt(dataToDecrypt, SECRET_KEY);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  
      return decryptedText;
  }
}

export const crypt = new Crypting();

export const mask = {
  mask: {
      backdropFilter: 'blur(5px)',
},}

export interface t {
  tr: any;
}