import React, { useCallback, useState } from "react";
import { Button, Modal, Form, notification, InputNumber, Input, ColorPicker, Checkbox, TreeSelect } from 'antd';
import { SettingOutlined,PlusOutlined } from '@ant-design/icons';
import { api, mask } from '../../const';

interface RolesModalProps {
    rd?: {
        id: number;
        name: string;
        access: any;
        admin: boolean;
        color: string;
    };
    updateData: any;
    options: any;
    newrole?: boolean;
}

const RolesEditModal: React.FC<RolesModalProps> = ({ rd, updateData, options, newrole }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [api1, contextHolder] = notification.useNotification({
        stack: { threshold: 1 },
    });
    const [nd, snd] = useState({
        id: rd?.id || 1,
        name: rd?.name || 'Название роли',
        access: rd?.access || [],
        admin: rd?.admin || false,
        color: rd?.color || '#ffffff',
    });
    const [value, setValue] = useState(['']);

    const sendNewData = useCallback(async () => {
        const openNotification = () => {
            api1.success({
                message: `${newrole ? 'Данные отправлены' : 'Данные успешно обновлены'}`,
                description: `Роль: ${nd.name}`,
                placement: 'bottom',
            });
        };
        try {
            const newData = {
                newrole: newrole, id: newrole ? nd.id : rd?.id, nd
            };
            const res = await api.post('/api/admin/roles/edit', newData, );
            if (res.status === 200) {
                openNotification();
                updateData();
                setModalOpen(false);
            }
        } catch (err) {
            console.log(err);
        }
    }, [newrole, api1, nd, updateData, rd?.id]);

    const onFinish = (values: any) => {
        sendNewData();
    };

    const handleChange = (key: string, value: any) => {
        snd(prevState => ({ ...prevState, [key]: value }));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const tProps = {
        treeData: options,
        value,
        onChange: (newValue: any) => handleChange('access', newValue),
        treeCheckable: true,
        showCheckedStrategy: TreeSelect.SHOW_ALL,
        placeholder: 'Please select',
        style: {
          width: '100%',
        },
    };

    return (
        <>
            {contextHolder}
            <Button type="default" style={{marginRight: 2}} onClick={() => setModalOpen(true)}>{newrole ? (<span>Добавить <PlusOutlined /></span>):(<span>Изменить <SettingOutlined /></span>)}</Button>
            <Modal
                title="Изменение ролей"
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                styles={mask}
                footer={[]}
            >
                <Form
                    name="roles-edit-modal-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={nd}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="ID"
                        name="id"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда айди роли!' }]}
                    >
                        <InputNumber changeOnWheel min={0} value={nd.id} onChange={(value) => handleChange('id', value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Название (латиницей)"
                        name="name"
                        rules={[{ required: true, message: 'Пожалуйста, введи сюда название роли!' }]}
                    >
                        <Input value={nd.name} onChange={(e) => handleChange('name', e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Цвет"
                        name="color"
                        rules={[{ required: true, message: 'Пожалуйста, выбери цвет для роли!' }]}
                    >
                        <ColorPicker
                            value={nd.color}
                            showText
                            disabledAlpha
                            onChange={(value) => handleChange('color', value.toHexString())}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Админская ли роль?"
                        name="admin"
                        valuePropName="checked"
                    >
                        <Checkbox value={nd.access} onChange={(e) => handleChange('admin', e.target.checked)}/>
                    </Form.Item>
                    <Form.Item
                        label="Доступ"
                        name="access"
                    >
                        <TreeSelect {...tProps} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Подтвердить(отправить)
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default RolesEditModal;
