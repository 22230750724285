import { Button, message, DatePicker, DatePickerProps, Divider, Form, FormProps, Input, Layout, Modal, Statistic, theme } from "antd";
import React, { useCallback, useEffect } from "react";
import { api, mask } from "../const";
import { User } from "../small-components/interfaces";

type FieldType = {
  reason?: string;
  date_expire?: any;
};

const BanModal: React.FC<{ban: any, success: any, fetchUser: any, user: any, getDate: any}> = ({ban,fetchUser,user,success,getDate}) => {
  const [opn, setOpn] = React.useState(false);
  const unban = async (ban: any,) => {
    await api.post('/punishments/unban', {steamid: user?.steamid, date_start: ban.date_start},);
    setOpn(false);
    success("Успешно разбанило");
    fetchUser();
  };

  return(
    <div>
    <Button onClick={() => setOpn(true)}>Посмотреть</Button>
      <Modal styles={mask} onCancel={() => setOpn(false)} open={opn} footer={(<span><Button style={{margin: 5}} danger onClick={() => unban(ban)}>Разбанить</Button><Button onClick={() => setOpn(false)}>Закрыть</Button></span>)}>
        <Statistic style={{margin: 10}} title="Дата начала бана" value={`${getDate(ban.date_start)}`} />
        <Statistic style={{margin: 10}} title="Дата конца бана" value={`${getDate(ban.date_expire, true)}`} />
        <Statistic style={{margin: 10}} title="Причина" value={ban.reason} />
        <Statistic style={{margin: 10}} title="Стимайди администратора" value={ban.steamid} />
        <Statistic style={{margin: 10}} title="Активно?" value={ban.active ? "Да" : "Нет"} />
      </Modal>
    </div>
  )
}

const UserProfilePage: React.FC<{data: any}> = ({data}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUser] = React.useState<User>();
  const [bans, setBans] = React.useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const steamid = urlParams.get('steamid');
  const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();
  const height = window.innerHeight;

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
    });
  };

  const fetchUser = useCallback(async () => {
    if(data.nickname !== "a" && steamid)
    {
      const userData = (await api.get("users/admin/profile/get?steamid="+steamid, )).data;
      const {user, bans} = userData;

      setUser(user);
      setBans(bans);
    } else {
      window.location.href = '/';
    }
  },[data])

  useEffect(() => {
    fetchUser();
  },[fetchUser]);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if(user)
    {
      const reqdata = {
        date_start: Math.floor(Date.now() / 1000),
        date_expire: values.date_expire.unix(),
        reason: values.reason,
        steamid: user.steamid,
      }
      try {
        await api.post('/punishments/ban', reqdata, );
        success("Успешно забанило");
        fetchUser();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const calendarOnChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date.unix());
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDate = (date: number, expire?: boolean) => {
    date = date * 1000
    const d = new Date((date));
    return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + (!expire ? " " + d.getHours() + ":" + (d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes()) : '')
  }

  return(
    <div style={{display: 'flex', padding: '0 15%'}}>
      {contextHolder}
      <Layout
        style={{ borderRadius: borderRadiusLG, background: colorBgContainer, height: height - 200, width: '40%', margin: '10px' }}
      >
        {user ? (
          <div className="content-of-user" style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column', padding: "50px"}}>
            <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
              <img style={{height: 50, borderRadius: 50, marginRight: 10}} alt="aa" src={user.avatar} />
              <h1>{user.nickname}</h1>
            </div>
            
            <Divider />
            <div className="user">
              <h2>Стимайди:</h2>
              <p>{user.steamid}</p>
              <h2>Роль на сервере:</h2>
              <p>{user.roles.serverrole}</p>
              <h2>Роли проекта:</h2>
              <span>{user.roles.siteroles.map((role: any, index: number, arr: any) => (index !== arr.length -1 ? role + ", " : role))}</span>
              <h2>Пользователь о себе:</h2>
              <p>{user.about}</p>
            </div>
          </div>
        ) : <span></span>}
      </Layout>
      <Layout
        style={{ borderRadius: borderRadiusLG, background: colorBgContainer, height: height - 200, width: '30%', margin: '10px' }}
      >
        <div style={{textAlign: 'center', padding: 50}}>
          <h1 style={{marginBottom: 45}}>Наказания</h1>
          <Divider />
          
          <div style={{display: 'flex', justifyItems: 'center', flexDirection: 'column'}}>
            <div style={{overflow: 'auto', maxHeight: 200}}>
              <table className="servers-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Даты</th>
                    <th rowSpan={2}>Действия</th>
                  </tr>
                  <tr>
                    <th>Дата старта бана</th>
                    <th>Дата окончания бана</th>
                  </tr>
                </thead>
                <tbody>
                  {bans.length > 0 ? (
                    bans.map((ban: any, index: number) => (
                      <tr style={ban.active ? {backgroundColor: 'rgba(255,0,0,0.3)'} : {backgroundColor: 'rgba(0,255,0,0.3)'}} key={index}>
                        <td>{`${getDate(ban.date_start)}`}</td>
                        <td>{`${getDate(ban.date_expire, true)}`}</td>
                        <td><BanModal success={success} fetchUser={fetchUser} user={user} getDate={getDate} ban={ban}/></td>
                      </tr>
                    ))
                  ):(<tr><td style={{padding: 15}} colSpan={4}>Нету банов... хочешь исправить?))))</td></tr>)}
                </tbody>
              </table>
            </div>
            <Form
              name="ban_form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, marginTop: 25 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label="Дата окончания"
                name="date_expire"
                rules={[{ required: true, message: 'Введи дату окончания!' }]}
              >
                <DatePicker onChange={calendarOnChange} />
              </Form.Item>

              <Form.Item<FieldType>
                label="Причина"
                name="reason"
                rules={[{ required: true, message: 'Введи причину!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default UserProfilePage;
