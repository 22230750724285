import { Divider, Layout,theme, Select, Space, } from "antd";
import type { SelectProps } from 'antd';
import React, {useEffect, useState} from "react";

import "../css/servers.css";
import { api } from "../const";

const options: SelectProps['options'] = [];


const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState([]);
  const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();

  const height = window.innerHeight;

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const res = (await api.get("/api/logs", )).data;
        setLogs(res.reverse());
      } catch (err) {
        console.log(err);
      }
    }
    fetchLogs();
  },[])

  return(
    <Layout
      style={{ borderRadius: borderRadiusLG, background: colorBgContainer, height: height - 200 }}
    >
      <div className="content-of-servers" style={{width: "100%", height: height -220, display: 'flex', flexDirection: 'column', padding: "50px"}}>
        <h1 style={{width: "100%", textAlign: "center"}}>Логи всех ресурсов проекта</h1>
        <Divider />
        <div style={{overflow: "auto"}}>
          <LogsTable logs={logs}/>
        </div>
      </div>
    </Layout>
  )
}

const LogsTable: React.FC<{logs: any}> = ({logs}) => {

  function gettingDate(date: any){
    date = new Date(date);
    const time = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
    const dd = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        date: date.getDate(),
        time,
    }
    return dd.date+'.'+dd.month+'.'+dd.year+', '+dd.time;
  }

  return(
    <table className="servers-table">
      <thead>
        <tr>
          <th>Время</th>
          <th>Тип</th>
          <th>Сообщение</th>
          <th>Уровень</th>
          <th style={{width: 670}}>Ошибка</th>
        </tr>
      </thead>
      <tbody>
        {logs.length > 0 ? (
          logs.map((log: any, index: number) => (
            <tr key={index} style={{backgroundColor: log.type === "err" ? "rgba(255, 0, 0, 0.6)" : log.type === "warn" ? "rgba(255, 191, 0, 0.6)" : log.type === "server" ? "rgba(255, 191, 0, 0.6)" : "white"}}>
              <td width={180}>{gettingDate(log.time)}</td>
              <td>{log.type}</td>
              <td>{log.msg || log.action}</td>
              <td>{log.level ? log.level : ''}</td>
              <td style={{width: 250, textAlign: "left", padding: '5px'}}>{log.err ? log.err.split('\n').map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                )) : ''}
              </td>
            </tr>
          ))
        ):(
          <tr><td className="nodata-td" colSpan={8}>Нету данных :с (womp-womp)</td></tr>
        )}
      </tbody>
    </table>
  )
}

export default LogsPage;
